const config = {
  studylistRoute: false,
  measurementsPanel: false,
  headerBackButton: false,
  toolbar: [
    // 'MeasurementTools',
    'Zoom',
    'WindowLevel',
    'Pan',
    // 'Capture',
    'Layout',
    'MPR',
    'Crosshairs',
    'MoreTools',
  ],
};
export default config;
